.personal-info {
     .personal-info-body {
          background: #eeeeee;
          height: calc(100vh - 60px);
          overflow-y: auto;
          .personal-info-body-block {
               background-color: #fff;
               padding: 0 10px 5px;
               p {
                    margin-bottom: 0;
               }
               &--item {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    padding: 15px 0;
                    border-bottom: 1px dashed #9db2bf;
                    .title {
                         font-weight: 700;
                         width: 130px;
                    }
               }
          }
          .title-main {
               margin-top: 7px;
               font-weight: 600;
               font-size: 15px;
               margin-bottom: 7px;
          }
          .personal-info-body--form {
               .form-item--block {
                    background-color: #fff;
                    padding: 10px 15px 2px;
               }
               .button-group {
                    padding: 0 15px;
                    .ant-btn {
                         height: 50px;
                         width: 100%;
                         background-color: var(--main-color);
                         color: var(--background-header-color_text);
                         border-radius: 10px;
                         font-weight: 500;
                         font-size: 16px;
                    }
               }
          }
     }
}
