
.body-screen {
     height: calc(100vh - 120px);
     overflow-y: auto;
     overflow-x: hidden;
     .condition-block {
          padding: 0 5px;
          margin-bottom: 65px;
          .title {
               font-weight: 800;
               font-size: 19px;
               margin-bottom: 2px;
          }
          .special {
               text-transform: uppercase;
               font-weight: 800;
               font-size: 20px;
          }
          .note {
               color: red;
               font-style: italic;
          }
     }
     .loan-now {
          padding: 0 5px;
          height: 50px;
          position: fixed;
          bottom: 60px;
          width: 100%;
          .ant-btn {
               width: 100%;
               border-radius: 15px;
               height: 50px;
               font-weight: 500;
               font-size: 15px;
          }
     }
     .main-banner {
          width: 100vw !important;
     }
     .sub-banner {
          width: 48.5vw !important;
          height: 22vh !important;
     }
}