.my-info {
     .body-my-info {
          background-color: #eeeeee;
          height: calc(100vh - 60px);
          p {
               margin-bottom: 0;
          }
          &--block {
               background-color: #fff;
               padding: 0 10px;
               &__item {
                    height: 80px;
                    border-bottom: 1px dashed #9db2bf;
                    display: flex;
                    align-items: center;
                    position: relative;
                    .prefix-icon {
                         font-size: 15px;
                         margin-right: 5px;
                         color: #526d82;
                    }
                    .text-block {
                         padding-right: 40px;
                         .title {
                              font-size: 16px;
                              color: #526d82;
                              line-height: 17px;
                              font-weight: 500;
                              margin-bottom: 5px;
                         }
                         .sub-title {
                              font-size: 13px;
                              color: #7B8FA1;
                              line-height: 15px;
                         }
                    }
                    .surfix-icon {
                         font-size: 19px;
                         position: absolute;
                         right: 15px;
                         color: #526d82;
                    }
                    .done-icon {
                         position: absolute;
                         top: 1px;
                         right: 0px;
                    }
                    &:hover {
                         cursor: pointer;
                    }
               }
          }
     }
     .note {
          padding: 0 15px;
          color: #6B728E;
     }
     .button-action {
          padding: 0 15px;
          .fake-button {
               display: flex;
               align-items: center;
               justify-content: center;
               height: 50px;
               width: 100%;
               background-color: var(--main-color);
               color: var(--background-header-color_text);
               border-radius: 10px;
               font-weight: 500;
               font-size: 16px;
               &:hover {
                    cursor: pointer;
               }
          }
     }
}