.times {
     display: flex;
     flex-wrap: wrap;
     margin-top: 10px;
     margin-bottom: 10px;
     &--item {
          text-align: center;
          width: 75px;
          padding: 5px;
          background-color: #DDE6ED;
          color: #526D82;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 5px;
          &:hover {
               cursor: pointer;
          }
          &.active {
               background-color: var(--main-color);
               color: #fff;
               font-weight: 500;
          }
     }
}
.money-per-month {
     .title {
          color: #526D82;
     }
     .sub-title {
          color: #526D82;
     }
}
.contract-component-dialog {
     width: 600px !important;
     .ant-modal-footer {
          display: none;
     }
}
@media (max-width: 767px) {

     .ant-modal.contract-component-dialog {
         max-width: calc(100vw - 16px);
         margin: 8px auto;
     }
}