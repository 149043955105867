.bank {
     .bank-body {
          background: #eeeeee;
          height: calc(100vh - 60px);
          .form-body {
               background-color: #fff;
               padding: 15px 15px 5px;
               margin-bottom: 15px;
          }
          .btn-group {
               padding: 0 15px;
               .btn-submit {
                    height: 50px;
                    width: 100%;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 16px;
               }
          }
          .bank-body-block {
               background-color: #fff;
               padding: 0 15px 5px;
               p {
                    margin-bottom: 0;
               }
               &--item {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    padding: 15px 0;
                    border-bottom: 1px dashed #9db2bf;
                    .title {
                         font-weight: 700;
                         width: 130px;
                    }
                    .value {
                         font-size: 16px;
                    }
               }
          }
     }
}
