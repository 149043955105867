.profile-block {
     background-color: #eeeeee;
     height: calc(100vh - 55px);
     .header-banner {
          height: calc(100vw / 100 * 51);
     }
}

.body-profile {
     &--block {
          background-color: #fff;
          padding: 0 10px;
          &__item {
               height: 50px;
               border-bottom: 1px dashed #9db2bf;
               display: flex;
               align-items: center;
               .prefix-icon {
                    font-size: 23px;
                    margin-right: 15px;
                    color: #526d82;
               }
               .title {
                    font-size: 16px;
                    color: #526d82;
                    font-weight: 500;
               }
               .surfix-icon {
                    font-size: 19px;
                    position: absolute;
                    right: 15px;
                    color: #526d82;
               }
               &:hover {
                    cursor: pointer;
               }
          }
     }
}
.carousel-phone-container {
     margin-top: 10px;
     // height: 400px;
     .content-item {
          align-items: center;
          border-bottom: 1px solid rgba(214, 49, 49, 0.3);
          color: #046db5;
          background-color: #D9F3FE;
          display: flex;
          font-size: 12px;
          font-weight: 600;
          height: 31px;
          line-height: 12px;
          padding: 8px 5px 0;
          i {
               color: #fff;
               font-size: 10px;
               margin-right: 8px;
          }
          span {
               color: #2b2b2b;
               font-size: 12px;
               padding: 0 3px;
          }
     }
}
// @media (min-width: 768px) {
//      .profile-block {
//           .header-banner {
//                height: 50vh;
//           }
//      }
// }
// @media (max-width: 549px) and (min-width: 470px) {
//      .profile-block {
//           .header-banner {
//                height: 40vh;
//           }
//      }
// }
// @media (max-width: 469px) and (min-width: 370px) {
//      .profile-block {
//           .header-banner {
//                height: 37vh;
//           }
//      }
// }
// @media (max-width: 369px) {
//      .profile-block {
//           .header-banner {
//                height: 29vh;
//           }
//      }
// }
