.banner-background {
     height: calc(100vw / 100 * 52);
}
.login-form {
     padding: 0 15px;
     .ant-form-item {
          margin-bottom: 8px !important;
          .ant-input-affix-wrapper {
               .ant-input-prefix {
                    margin-right: 20px;
                    font-size: 20px;
               }
          }
          // &:nth-child(2) {
          //      margin-bottom: 18px !important;
          // }
     }
     .button-ant-form {
          height: 40px;
          border-radius: 10px;
          font-size: 15px;
          font-weight: 500;
     }
}

// @media (min-width: 768px) {
//      .banner-background {
//           height: 35vh;
//      }
// }

// @media (max-width: 640px) {
//      .banner-background {
//           height: 300px;
//      }
// }
// @media (max-width: 550px) {
//      .banner-background {
//           height: 250px;
//      }
// }
// @media (max-width: 450px) {
//      .banner-background {
//           height: 200px;
//      }
// }
// @media (max-width: 350px) {
//      .banner-background {
//           height: 150px;
//      }
// }
