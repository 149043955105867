:root {
  --main-color: #c81c5f;
  --background-header-color: #c81c5f;
  --background-header-color_text: #fff;
  --active-menu-color: #c81c5f;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-avatar > img {
  border-radius: 50%;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
  list-style: none;
  text-align: center;
  width: 45vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media (max-width: 960px) {
  .slogan {
    font-size: calc(3.5vw + 3.5vh + 2vmin) !important;
  }
  .slogan-small {
    font-size: calc(1vw + 1vh + 2vmin) !important;
  }
  .text-meta {
    font-size: calc(1vw + 1vh + 2vmin) !important;
  }
  .text-meta-small {
    font-size: calc(0.6vw + 0.6vh + 2vmin) !important;
  }
  .carousel {
    max-width: 80vw;
    margin: 3vw;
  }
  .carousel-item {
    min-height: 160px;
  }
  .row-container {
    min-width: 100%;
  }
}
@media (min-width: 961px) {
  .carousel-item {
    min-height: 500px;
  }
  .row-container {
    min-width: 80%;
  }
  .slogan {
    font-size: calc(2vw + 2vh + 2vmin) !important;
  }
  .carousel {
    max-width: 40vw;
    margin: 3vw;
  }
  .slogan-small {
    font-size: calc(1vw + 1vh + 2vmin) !important;
  }
  .text-meta {
    font-size: calc(0.1vw + 0.1vh + 2vmin) !important;
  }
  .text-meta-small {
    font-size: calc(0.05vw + 0.05vh + 2vmin) !important;
  }
}
.btn {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background: var(--main-color) !important;
}
.btn:hover {
  background: rgba(68, 73, 85, 0.8);
}

.ant-slider-handle {
  margin-top: -10px !important;
}

.ant-form-item {
  margin-bottom: 15px !important;
}

.link-signup {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 0 10px 5px;
  text-align: right;
  color: #0c7feb;
  text-decoration: initial;
  font-weight: 600;
}
.link-signup span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sign-container {
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.con-dau {
  text-align: center;
  /* width: 50%; */
  font-weight: 600;
}

.chu-ky {
  text-align: center;
  width: 50%;
  font-weight: 600;
}

.chu-ky.not-sign {
  width: 100%;
}

/* New UI */
.header-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: var(--background-header-color);
  color: var(--background-header-color_text);
  font-size: 16.5px;
  font-weight: bold;
  margin-bottom: 5px;
  position: relative;
}
.header-screen .icon-header {
  position: absolute;
  left: 15px;
  height: 58px;
  width: 30px;
  display: flex;
  align-items: center;
  font-size: 22px;
}
.header-screen .icon-header:hover {
  cursor: pointer;
}
.divider-custom {
  margin: 5px 0 !important;
}
.text-center {
  text-align: center !important;
}
.ant-btn-primary {
  background: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pdl-5 {
  padding-left: 5px;
}

.ant-modal {
  top: 10px !important;
}

/* Contract */
.company-name-title {
  font-size: 17px;
  font-weight: 700;
  color: var(--main-color);
}
.main-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 21px;
  text-align: center;
}
.note-contract {
  font-weight: bold;
}
.noti-modal {
  padding-bottom: 0 !important;
  top: 50% !important;
  transform: translate(0, -50%);
  width: 280px !important;
  text-align: center;
  font-weight: 700;
}
.noti-modal .ant-modal-content {
  border-radius: 10px;
}
.noti-modal .ant-modal-header {
  display: none;
}
.noti-modal .ant-modal-footer {
  display: none;
}
.noti-modal .ant-modal-close {
  display: none;
}
.to-cskh {
  background-color: var(--main-color);
  border-radius: 12px;
  padding: 5px;
  font-weight: 700;
  color: #fff;
}
.to-cskh:hover {
  cursor: pointer;
}
