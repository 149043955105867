.cskh {
     .banner-header-body {
          .main-banner {
               width: 100vw;
               // max-height: 35vh;
               height: calc(100vw / 100 * 52);
          }
     }
     .main-body {
          .main-title {
               line-height: 15px;
               font-weight: 700;
          }
          .button-group {
               padding: 10px 15px 2px;
               .btn-genaral {
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
               }
               &:hover {
                    cursor: pointer;
               }
               .btn-submit {
                    background-color: var(--main-color);
                    color: var(--background-header-color_text);
               }
               .btn-telegram {
                    width: auto;
                    margin-right: 20px;
                    color: var(--background-header-color_text);
                    img {
                         width: 50px;
                    }
               }
               .btn-zalo {
                    width: auto;
                    color: var(--background-header-color_text);
                    img {
                         width: 50px;
                    }
               }
          }
     }
}
