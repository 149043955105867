@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.ant-btn-primary{
  border-color: none  !important;
  /* background: darkred  !important; */
  text-shadow: none  !important;
 
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body-welcome {
  background: #250DC8;
}

.flex-container {
  /* background: #250DC8; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Lato', sans-serif !important;
  padding: 32px 15px;
  height: 100vh;
  animation: fadeIn 3s;
}

.block-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-mb {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 2rem;
  object-fit: cover;
  object-position: center;
}

.app-name {
  font-size: 2.0rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
}

.app-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
}

.svg-inline--fa {
  color: #3897F0;
  width: 15px;
  position: relative;
  top: -5px;
  left: 10px;
}

.btn-signup {
  background: #fff;
  color: #2809DF;
  cursor: pointer;
  border-radius: 0.3rem;
  padding: 1rem 4.5rem;
  font-size: 1.1rem;
  box-shadow: 0 0 20px #00000010;
  border: 0;
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin-top: 2.5rem;
}

.btn-signup::selection {
  color: #fff;
  background: #1890ff;
}

.block-footer {
  color: #fff;
  font-size: .9rem;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  padding: 22px 0;
}

@keyframes fadeIn {
  0% { 
    opacity: 0;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

.ct-table td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
}

.ct-table .cl-1 {
  font-weight: bold;
}

.ct-table .cl-2 {
  font-weight: 500;
  color: rgb(240, 21, 21);
}

.rv-container {
  width: 100%;
}

.rv-container img {
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px)  {
  .rv-container img {
    width: 100%;
    max-height: 200px;
  }
}